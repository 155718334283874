import { computed, defineAsyncComponent } from 'vue';
import { defineComponent, reactive, toRefs } from 'vue';
import { ElAvatar, ElTag, ElSpace, ElProgress } from 'element-plus';
export default defineComponent({
  name: 'TrajectoryInfoWindow',
  components: {
    ElAvatar,
    ElTag,
    ElSpace,
    ElProgress,
    List: defineAsyncComponent(() => import('@/components/List')),
    AddressLabel: defineAsyncComponent(() => import('@/components/AddressLabel'))
  },
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      actions: []
    });
    const contents = computed(() => !props.info ? [] : [{
      label: 'IMEI号',
      content: props.info.mdtid
    }, {
      label: '定位方式',
      content: props.info.posTypeStr
    }, {
      label: '速度',
      content: props.info.speed / 10
    }, {
      label: '状态',
      content: props.info.mdtstatus & 1 ? '启动' : '熄火'
    }, {
      label: '数据时间',
      content: props.info.posTime
    },
    // {
    //   label: '共存模式',
    //   content: props.info.disturbStatus == 1?'课堂模式':'关闭',
    //   title:props.info.disturbStatus == 1?'最多设置10个时段作为课堂模式时段,在课堂模式下设备为静音模式（按键和来电无声）':'',
    // },
    {
      label: '位置',
      contentSlot: 'address'
    }]);
    return {
      ...toRefs(refData),
      contents
    };
  }
});